/**
 * @mixin
 */
export default {
  mounted() {
    const { loading } = this.$options;
    /**
     * We are looking for loading: {} in the component instance
     * If we find it, then we search for `methods` and `property` attributes so we can handle them
     */
    if (loading) {
      const { methods, property, defaultValue } = loading;
      if (methods.length) {
        methods.forEach(method => {
          this.registerLoading(method, property, defaultValue);
        });
      }
      if (typeof methods === 'string') {
        this.registerLoading(methods, property, defaultValue);
      }
    }
  },

  methods: {
    /**
     * Intercepts a Promise (action) and sets a local property
     * to true before executing that promise. After promise is fulfilled or rejected,
     * the property is set to false
     *
     * @param {Promise} action promise to set loading before and after promise was fulfilled/rejects
     * @param {string} localProperty instance property name to set loading value
     * @param {string} [defaultValue] default property name to set
     */
    registerLoading(action, localProperty, defaultValue = false) {
      this[localProperty] = defaultValue;
      const originalMethod = this[action];
      this[action] = () => {
        this[localProperty] = true;
        return originalMethod
          .apply(this)
          .then(data => {
            this[localProperty] = false;
            return Promise.resolve(data);
          })
          .catch(() => {
            this[localProperty] = false;
          });
      };
    }
  }
};
